<template>
  <div class="dashboard p-5" id="dementie-information">
    <div class="row">
      <div class="col-sm-12 down">
        <div>
          <h1 class="intro-section-title">Niet Pluis - Overig</h1>
          <div class="row">
            <p>
              Waar verwachten we meer en waar verwachten we minder mensen met dementie? 
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <card :cardId="'Aantal geïdentificeerde mensen t.o.v. verwachting per regio'" :hovertext="'Het verwachte aantal mensen met dementie is tot stand gekomen met behulp van een regressiemodel op alle geïdentificeerde mensen met dementie in Nederland. Hierin zijn kenmerken verwerkt zoals leeftijd, geslacht, woonsituatie, inkomen, gezondheidskenmerken en zorggebruik. Het hebben van ieder kenmerk kan de kans op dementie vergroten of verkleinen. In iedere zorgkantoorregio is gecorrigeerd voor bovenstaande factoren om een verwacht aantal mensen met dementie te berekenen. In een regio met veel ouderen is het aantal bijvoorbeeld hoger dan in een regio met een relatief jonge bevolking. Het verschil tussen het werkelijke aantal mensen met dementie en het verwachte aantal kan aanknopingspunten geven voor vervolgonderzoek en verbetering.'" :x="gemeente_names" :y="location_names" :z="factors.map(value => `${value}%`)" :xtitle="xtitle" :ytitle="ytitle" :ztitle="ztitle" :tableHeight='7400'> 
          <template slot="card-header">Aantal geïdentificeerde mensen t.o.v. verwachting per regio</template>
            <div slot="card-body">
              Op deze kaart van Nederland vindt u de onder- en overidentificatie per dementienetwerk.
              Deze data is uitsluitend voor het jaartal 2020 beschikbaar.
              Met kenmerken van de bevolking, zoals leeftijd, geslacht, zorggebruik en gezondheid, berekenen wij een verwacht aantal mensen met dementie in de regio.
              Een waarde van 120% betekent dat in die regio 20% meer mensen met dementie zijn geïdentificeerd dan dat we modelmatig hadden verwacht.
              <br><br>
              Een verschil kan meerdere oorzaken hebben: de prevalentie van dementie wijkt in deze regio af van het landelijk gemiddelde,
              mensen met dementie gebruiken andere zorg dan in andere delen van Nederland of de wijze van diagnose stellen wijkt af.
              Dit laatste kan regio’s aanknopingspunten geven om de mensen met dementie nóg beter van een passende diagnose te voorzien.              <div class="d-flex justify-content-center align-items-center">
                <choropleth  v-if="load.graphOne" :colorscale="colorscale" :zmax='120' :zmin="70" :z="factors" :locations="location_factors" :text="location_names"  :colorbar="{ ticksuffix: '%' }" :hovertemplate= "'<extra><br>%%{z:.1f}<br>%{text}<br></extra>'"/>
              </div>
            <!-- <div> {{selectedLevel}} </div>
            <div> {{selectedRegion}} </div> -->
          </div>
        </card>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col-sm-12">
        <card :cardId="'niet-plus-overig-1'" :hovertext="'Het verwachte aantal mensen met dementie is tot stand gekomen met behulp van een regressiemodel op alle geïdentificeerde mensen met dementie in Nederland. Hierin zijn kenmerken verwerkt zoals leeftijd, geslacht, woonsituatie, inkomen, gezondheidskenmerken en zorggebruik. Het hebben van ieder kenmerk kan de kans op dementie vergroten of verkleinen. In iedere zorgkantoorregio is gecorrigeerd voor bovenstaande factoren om een verwacht aantal mensen met dementie te berekenen. In een regio met veel ouderen is het aantal bijvoorbeeld hoger dan in een regio met een relatief jonge bevolking. Het verschil tussen het werkelijke aantal mensen met dementie en het verwachte aantal kan aanknopingspunten geven voor vervolgonderzoek en verbetering.'">
          <template slot="card-header">Aantal geïdentificeerde mensen t.o.v. verwachting per regio</template>
            <div slot="card-body">
              Op deze kaart van Nederland vindt u de onder- en overidentificatie per {{selectedLevel}}.
              Met kenmerken van de bevolking, zoals leeftijd, geslacht, zorggebruik en gezondheid, berekenen wij een verwacht aantal mensen met dementie in de regio. Een waarde van 120% betekent dat in die regio 20% meer mensen met dementie zijn geïdentificeerd dan dat we op basis van het voorspelmodel hadden verwacht. 
              <br><br>
              Een verschil kan meerdere oorzaken hebben: de prevalentie van dementie wijkt in deze regio af van het landelijk gemiddelde, mensen met dementie gebruiken andere zorg dan in andere delen van Nederland of de wijze van diagnose stellen wijkt af. Dit laatste kan regio’s aanknopingspunten geven om de mensen met dementie nóg beter van een passende diagnose te voorzien.
              <Table :x="location_names" :y="factors" :xtitle="xtitle" :ytitle="ytitle" />
            </div>
        </card>
      </div>
    </div> -->

    <Footer :adclogo="adclogo" :erasmuslogo="erasmuslogo"/>
  </div>
</template>

<script>

import Choropleth from '@/components/Choropleth'
// import CardSmall from '../components/CardSmall'
// import Table from '../components/Table'
import Card from '../components/Card'
// import SimpleBarChart from '../components/SimpleBarChart'
import { mapGetters } from 'vuex'
import Footer from '../components/Footer'
import adclogo from '@/assets/ADC full logo.png'
import erasmuslogo from '@/assets/erasmuslogo.png'
import VueMeta from 'vue-meta';

export default {
  mixins: [VueMeta],
  metaInfo: {
    title: 'Niet Pluis - Overig',
  },
  components: { Choropleth, Card, Footer},
  data () {
    return {
      adclogo: adclogo,
      erasmuslogo: erasmuslogo,
      factors: [],
      variabele: [],
      probability: [],
      colorbar: [],
      location_names: [],
      gemeente_names: [],
      location_factors: [],
      load: {
        graphOne: false,
      },
      // tableData: {
      //   x: ['X1', 'X2', 'X3'], // Example x values
      //   y: ['Y1', 'Y2', 'Y3'], // Example y values
      //   xtitle: 'X Title',
      //   ytitle: 'Y Title',
      // },
      xtitle: 'Regio namen',
      ytitle: 'Percentage',
      ztitle: 'Gemeente namen',
      colorscale: [
        ['0.0', 'rgb(205, 230, 246)'],
        ['0.25', 'rgb(115, 182, 224)'],
        ['0.50', 'rgb(37, 116, 169)'],
        ['0.75', 'rgb(16, 63, 105)'],
        ['1.0', 'rgb(20, 66, 115)']
      ]
    }
  },
  watch: {
    '$store.state.selectedLevel': function () {
      this.loadMapData(this.$store.state.selectedLevel)
    },
    '$store.state.selectedYear': function () {
      this.loadMapData(this.$store.state.selectedLevel)
    }
  },
  computed: {
      ...mapGetters(['selectedRegion', 'selectedLevel', 'selectedYear'])
  },
  mounted () {
    this.loadMapData(this.selectedLevel)
  },
  methods: {
    loadMapData (level) {
      if (level === 'zorgkantoor' || level === 'landelijk') {
        this.$http.get(`/api/gemeente_totaal/gemeente_factorZK/?jaar=2020`).then(
          (response) => {
            this.location_factors = response.data.map(d => d.gemeentecode)
            this.location_names = response.data.map(d => d.zorgkantoorregionaam)
            this.gemeente_names = response.data.map(d => d.gemeentenaam)
            this.factors = response.data.map(d => (d.factor * 100).toFixed(1))
            this.load.graphOne = true
          }
        )
      }
      else {
        this.$http.get(`/api/gemeente_totaal/gemeente_factorDN/?jaar=2020`).then(
          (response) => {
            this.location_factors = response.data.map(d => d.gemeentecode)
            this.location_names = response.data.map(d => d.dementienetwerknaam)
            this.gemeente_names = response.data.map(d => d.gemeentenaam)
            this.factors = response.data.map(d => (d.factor * 100).toFixed(1))
            this.load.graphOne = true
          }
        )
      }
    }
  }
}

</script>

<style scoped>

.intro-section-title{
  font-weight: bold;
  margin-bottom: 2rem;
}

</style>